import { CommentFieldsEnum } from "src/shared/enums/comment-field.enum";
import { IReportDetails } from "src/shared/interfaces/report-details.interface";

export namespace GPT {

    export class SetValues {
        public static readonly type = '[GptReport] Set Gpt Values';
        constructor(public reportDetails?: IReportDetails, public commentField?: CommentFieldsEnum) {}
    }
    
    export class SetPrompt {
        public static readonly type = '[GptReport] Set prompt';
        constructor(public prompt?: string) {}
    }

    export class SetGeneralPrompt {
        public static readonly type = '[GptReport] Set general prompt';
        constructor(public generalPrompt?: string) {}
    }

    export class FetchPrompts {
        public static readonly type = '[Prompt] Fetch prompts';
    }

    export class UpdateGptPrompts {
        public static readonly type = '[content] Update gpt prompt';
    }

    export class FetchGptResult {
        public static readonly type = '[content] Fetch gpt Result';
    }

    export class FetchMultipleGptResults {
        public static readonly type = '[content] Fetch multiple gpt Results';
    }

    export class FetchAllPromptsAngGenerateGPT {
        public static readonly type = '[Prompt] Fetch all prompts and gpt Results';
    }

}
