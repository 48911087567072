import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IGptChatGprDToOut, IGptPromptDtoIn, IGptPromptKeysDtOut, IGptPromptsDtOut } from "src/shared/interfaces/gpt.interface";
import { GptService } from "src/shared/services/gpt.service";
import { ReportPrompt } from "../actions/report-prompt.action";
import { PromptEnum } from "src/shared/enums/prompt-enum.enum";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { state } from "@angular/animations";


export interface ReportPromptStateModel {
    prompts: string[]
    selectedPrompt: PromptEnum
}


function generateKeys(state: ReportPromptStateModel): string[] {

    var baseString = 'report_management_tool_'
    var keys: string[] = []

    switch (state.selectedPrompt) {
        case PromptEnum.OVERALL:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'overall_star_' + i) }
            break;
        case PromptEnum.VISION:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'vision_star_' + i) }
            break;
        case PromptEnum.OLFACTION:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'olfaction_star_' + i) }
            break;
        case PromptEnum.TASTE:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'taste_star_' + i) }
            break;
        case PromptEnum.FINAL_SENSATION:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'final_sensation_star_' + i) }
            break;
        case PromptEnum.TEXTURE:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'texture_star_' + i) }
            break;
        case PromptEnum.ABSENCE_OF_FAULTS:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'absence_of_faults_star_' + i) }
            break;
        case PromptEnum.FRUITINESS:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'fruitiness_star_' + i) }
            break;
        case PromptEnum.BITTERNESS:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'bitterness_star_' + i) }
            break;
        case PromptEnum.PUNGENCY:
            for (let i = 0; i < 4; i++) { keys.push(baseString + 'pungency_star_' + i) }
            break;
        case PromptEnum.SUGGESTION:
            keys.push(baseString + 'suggestion')
            break;
        case PromptEnum.FOOD_PAIRING:
            keys.push(baseString + 'food_pairing')
            break;
        case PromptEnum.GENERAL:
            keys.push(baseString + 'common_key')
            break;
    }

    return keys
}


@State<ReportPromptStateModel>({
    name: 'reportPrompt',
})

@Injectable()
export class ReportPromptState {

    constructor(private gptService: GptService) {}
    

    @Selector()
    public static prompts(state: ReportPromptStateModel): string[] {
        return state.prompts;
    }

    @Action(ReportPrompt.SetSelectedPrompt)
    public setSelectedPrompt(ctx: StateContext<ReportPromptStateModel>, action: ReportPrompt.SetSelectedPrompt): void {
        ctx.patchState({
            selectedPrompt: action.selectedPrompt
        });
    }

    @Action(ReportPrompt.FetchPrompts)
    public fetchPrompts(ctx: StateContext<ReportPromptStateModel>): Observable<IGptPromptDtoIn> {
        const stateModel: ReportPromptStateModel = ctx.getState();

        const keys: IGptPromptKeysDtOut = {
            keys : generateKeys(stateModel)
        }
        return this.gptService.getPromptForKeys(keys).pipe(
            tap((result: IGptPromptDtoIn) => {
                ctx.patchState({ prompts: result.prompts.map(item => item.prompt) })
            })
        );
    }

    @Action(ReportPrompt.SetPrompts)
    public setPrompts(ctx: StateContext<ReportPromptStateModel>, action: ReportPrompt.SetPrompts): void {
        ctx.patchState({
            prompts: action.prompts.filter(prompt => prompt.trim() !== '')
        });
    }
    
    
    @Action(ReportPrompt.UpdatePrompts) 
    public updategptPrompt(ctx: StateContext<ReportPromptStateModel>, action: ReportPrompt.UpdatePrompts): Observable<void> {
        const state: ReportPromptStateModel = ctx.getState();

        const keys = generateKeys(state)
        
        type Prompt = { key: string; prompt: string };
        let promptsValues: Prompt[] = []; 
    

        for (let i = 0; i < state.prompts.length; i++) {
            promptsValues.push({key: keys[i], prompt: state.prompts[i]})
        }

        const values: IGptPromptsDtOut = {
            prompts: promptsValues
        }

        return this.gptService.updateGptPrompts(values);
    }


}