import { FeatureEnum } from '../enums/features.enum';
import { RoleEnum } from '../enums/user-right.enums';
import { IFeatureList } from '../interfaces/feature-permissions.interface';

export const featuresWithPermissions: IFeatureList = {
    all_access: {
        feature: FeatureEnum.ALL_ACCESS,
        authorisation: [
            RoleEnum.FREELANCE_BASIC,
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
            RoleEnum.EXTERNAL_CP,
        ],
    },
    pretest_access: {
        feature: FeatureEnum.PRETEST_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
            RoleEnum.EXTERNAL_CP,
        ],
    },
    pretest_full_column_access: {
        feature: FeatureEnum.PRETEST_FULL_COLUMN_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    pretest_trad: {
        feature: FeatureEnum.PRETEST_TRAD,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    pretest_foodex_plan: {
        feature: FeatureEnum.PRETEST_FOODEX_PLAN,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    pretest_ups_export: {
        feature: FeatureEnum.PRETEST_UPS_EXPORT,
        authorisation: [RoleEnum.ADMIN_EXPORTS, RoleEnum.ACCOUNTING, RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    pretest_destructive_action: {
        feature: FeatureEnum.PRETEST_DESTRUCTIVE_ACTION,
        authorisation: [RoleEnum.ACCOUNTING, RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    announcement_access: {
        feature: FeatureEnum.ANNOUNCEMENT_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    announcement_trad: {
        feature: FeatureEnum.ANNOUNCEMENT_TRAD,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    announcement_export: {
        feature: FeatureEnum.ANNOUNCEMENT_EXPORT,
        authorisation: [RoleEnum.ADMIN_EXPORTS, RoleEnum.ACCOUNTING, RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    announcement_foodex: {
        feature: FeatureEnum.ANNOUNCEMENT_FOODEX,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    new_announcement_access: {
        feature: FeatureEnum.NEW_ANNOUNCEMENT_ACCESS,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    new_announcement_foodex: {
        feature: FeatureEnum.NEW_ANNOUNCEMENT_FOODEX,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    result_access: {
        feature: FeatureEnum.RESULT_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    result_ups_export: {
        feature: FeatureEnum.RESULT_UPS_EXPORT,
        authorisation: [RoleEnum.ADMIN_EXPORTS, RoleEnum.ACCOUNTING, RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    invoice_access: {
        feature: FeatureEnum.INVOICE_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    invoice_icnp_export_sta: {
        feature: FeatureEnum.INVOICE_ICNP_EXPORT_STA,
        authorisation: [RoleEnum.ACCOUNTING, RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    discount_access: {
        feature: FeatureEnum.DISCOUNTS_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    discount_edition: {
        feature: FeatureEnum.DISCOUNTS_EDITION,
        authorisation: [RoleEnum.SALES_MANAGEMENT, RoleEnum.BC_SUPER_MANAGEMENT],
    },
    discount_disabled_access: {
        feature: FeatureEnum.DISCOUNTS_DISABLED,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    planning_access: {
        feature: FeatureEnum.PLANNING_ACCESS,
        authorisation: [
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    planning_edition: {
        feature: FeatureEnum.PLANNING_EDITION,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    report_assignation_access: {
        feature: FeatureEnum.REPORT_ASSIGNATION_ACCESS,
        authorisation: [
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    result_validation_access: {
        feature: FeatureEnum.RESULT_VALIDATION_ACCESS,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT],
    },
    user_right_management_access: {
        feature: FeatureEnum.USER_RIGHTS_MANAGEMENT_ACCESS,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT],
    },
    report_management_tool_access: {
        feature: FeatureEnum.REPORT_MANAGEMENT_ACCESS,
        authorisation: [
            RoleEnum.FREELANCE_BASIC,
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    report_redaction_access: {
        feature: FeatureEnum.REPORT_REDACTION_ACCESS,
        authorisation: [
            RoleEnum.FREELANCE_BASIC,
            RoleEnum.BC_BASIC,
            RoleEnum.FOODEX_PLANNING_REPORTS,
            RoleEnum.ADMIN_EXPORTS,
            RoleEnum.ACCOUNTING,
            RoleEnum.SALES_MANAGEMENT,
            RoleEnum.BC_SUPER_MANAGEMENT,
        ],
    },
    report_management_assignation: {
        feature: FeatureEnum.REPORT_MANAGEMENT_ASSIGNATION,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT],
    },
    report_management_redaction: {
        feature: FeatureEnum.REPORT_MANAGEMENT_REDACTION,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT, RoleEnum.FREELANCE_BASIC],
    },
    report_management_prompts: {
        feature: FeatureEnum.REPORTS_MANAGEMENT_PROMPTS,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT, RoleEnum.FREELANCE_BASIC],
    },
    result_validation_tool: {
        feature: FeatureEnum.RESULT_VALIDATION_TOOL_ACCESS,
        authorisation: [RoleEnum.BC_SUPER_MANAGEMENT],
    },
};
