import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IReportDetails } from '../../interfaces/report-details.interface';
import { ReportManagementService } from '../../services/report-management.service';
import { ReportManagement } from '../actions/report-management.actions';
import { ReportAssignationState } from './report-assignation.state';
import { ReportRedactionState } from './report-redaction.state';
import { report } from 'process';
import { ValueTransformer } from '@angular/compiler/src/util';
import { CommentFieldsEnum } from 'src/shared/enums/comment-field.enum';

export interface ReportManagementStateModel {
    reportDetails: IReportDetails;
}

@State<ReportManagementStateModel>({
    name: 'reportManagement',
    defaults: {
        reportDetails: null,
    },
    children: [ReportAssignationState, ReportRedactionState],
})
@Injectable()
export class ReportManagementState {
    @Selector()
    public static reportDetails(state: ReportManagementStateModel): IReportDetails {
        return state.reportDetails;
    }

    constructor(private reportManagementService: ReportManagementService) {}

    @Action(ReportManagement.GetDetails)
    public getDetails(ctx: StateContext<ReportManagementStateModel>, action: ReportManagement.GetDetails): Observable<IReportDetails> {
        return this.reportManagementService
            .show(action.id)
            .pipe(tap((result: IReportDetails) => ctx.patchState({ reportDetails: result })));
    }

    @Action(ReportManagement.Update)
    public update(ctx: StateContext<ReportManagementStateModel>, action: ReportManagement.Update): Observable<IReportDetails> {
        return this.reportManagementService.update(action.details, action.id).pipe(
            tap((result: IReportDetails) => {
                ctx.patchState({ reportDetails: result });
            })
        );
    }

    @Action(ReportManagement.UpdateComment)
    public updateComment(ctx: StateContext<ReportManagementStateModel>, action: ReportManagement.UpdateComment) {
        const state: ReportManagementStateModel = ctx.getState();
        
        let newRedactorInfo;

        switch (action.commentField) {
          case CommentFieldsEnum.OVERALL:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                overall_comment: action.comment
            };
            break;
          case CommentFieldsEnum.CR2:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                cr2: action.comment
            };
            break;
          case CommentFieldsEnum.CR3:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                cr3: action.comment
            };
            break;
          case CommentFieldsEnum.CR4:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                cr4: action.comment
            };
            break;
          case CommentFieldsEnum.CR5:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                cr5: action.comment
            };
            break;
          case CommentFieldsEnum.SUGGESTION:
            newRedactorInfo = {
                ...state.reportDetails.redactor_content,
                suggestion: action.comment
            };
            break;
          default:
            return;
        }
    
        ctx.setState({
          ...state,
          reportDetails: {
            ...state.reportDetails,
            redactor_content: newRedactorInfo
          }
        });

    }

}
