import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HeaderErrorEnum } from "../enums/header-errors.enum";
import { SubUrlsEnum } from "../enums/sub-urls.enum";
import { IGptDtoIn, IGptPromptDtoIn, IGptPromptsDtOut, IGptPromptKeysDtOut, IGptChatGprDToOut, IGptChatGptDToIn } from "../interfaces/gpt.interface";
import { PromptEnum } from "../enums/prompt-enum.enum";


@Injectable({
    providedIn: 'root',
})

export class GptService {
    constructor(private http: HttpClient) {}

    public getPromptForKeys(keys: IGptPromptKeysDtOut): Observable<IGptPromptDtoIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .post<IGptPromptDtoIn>(environment.itqiApi + SubUrlsEnum.GPT_PROMPT, keys , { headers })
            .pipe(map((result: any) => result));
    }

    public updateGptPrompts(values: IGptPromptsDtOut): Observable<void> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');
        return this.http
        .put<void>(environment.itqiApi + SubUrlsEnum.GPT_PROMPT, values, { headers })
    }


    public getGptResultByPrompt(prompt: string): Observable<IGptDtoIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .post<IGptDtoIn>(environment.itqiApi + SubUrlsEnum.GPT, { prompt }, { headers })
            .pipe(map((result: any) => result));
    }

    public getGptResultsByPrompts(prompts: IGptChatGprDToOut): Observable<IGptChatGptDToIn> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http
            .post<IGptChatGptDToIn>(environment.itqiApi + SubUrlsEnum.GPT, prompts, { headers })
            .pipe(map((result: any) => result));
    }

}
