export enum PromptEnum {
    OVERALL = 'overall',
    VISION = 'vision',
    OLFACTION = 'olfaction',
    TASTE = 'taste',
    TEXTURE = 'texture',
    SUGGESTION = 'suggestion',
    FINAL_SENSATION = 'final_sensation',
    ABSENCE_OF_FAULTS = 'absence_of_faults',
    FRUITINESS = 'fruitiness',
    BITTERNESS = 'bitterness',
    PUNGENCY = 'pungency',
    FOOD_PAIRING ='food_pairing',
    GENERAL = 'general'
}