import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { CommentFieldsEnum } from "src/shared/enums/comment-field.enum";
import { IFoodPairingJuryContent, IReportCriteriaJuryContent, IReportDetails } from "src/shared/interfaces/report-details.interface";
import { GPT } from "../actions/gpt-report.action";
import { IGptDtoIn, IGptPromptDtoIn, IGptPromptsDtOut, IGptPromptKeysDtOut, IGptChatGptDToIn, IGptChatGprDToOut } from "src/shared/interfaces/gpt.interface";
import { generate, Observable } from "rxjs";
import { GptService } from "src/shared/services/gpt.service";
import { Interpolation } from "@angular/compiler";
import { concatMap, mergeMap, tap } from "rxjs/operators";
import { stat } from "fs";
import { ReportDetailsCriteriaEnum } from "src/shared/enums/report-details-main-criteria";


export interface GptReportStateModel {
    reportDetails: IReportDetails;
    commentField: CommentFieldsEnum;
    generalPrompt: string;
    prompt: string;
    gptResult: string;
    allPrompts: IGptChatGprDToOut
    allGptResults: IGptChatGptDToIn
}

const reportCommonKey = "report_management_tool_common_key"

function generateAllKeys(state: GptReportStateModel): string[] {

    const commentFields = [CommentFieldsEnum.OVERALL]

    if (state.reportDetails.options.comments_suggestions) {
        commentFields.push( CommentFieldsEnum.CR2, CommentFieldsEnum.CR3,
            CommentFieldsEnum.CR4, CommentFieldsEnum.CR5,
            CommentFieldsEnum.SUGGESTION)
    }
    if (state.reportDetails.options.food_pairing) {
        commentFields.push( CommentFieldsEnum.FOODPAIRING)
    }

    var keys: string[] = []

    keys.push(reportCommonKey)

    // all keys for state
    for (const commentField of commentFields) {
        keys.push(constructKey(state, commentField))
    }

    return keys
}

function generateKey(state: GptReportStateModel ): string {
   return constructKey(state, state.commentField)
}

function constructKey(state: GptReportStateModel, commentField: CommentFieldsEnum): string {
    var base = "report_management_tool_"
    var criteria_content

    switch (commentField) {
        case CommentFieldsEnum.SUGGESTION:
            return base + "suggestion"
        case CommentFieldsEnum.FOODPAIRING:
            return base + "food_pairing"
        case CommentFieldsEnum.OVERALL:
            return base + "overall_star_" + generateStars(state.reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === ReportDetailsCriteriaEnum.GENERAL).score)
        case CommentFieldsEnum.CR2:
            criteria_content = state.reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === ReportDetailsCriteriaEnum.CR2)
            return base + criteria_content.label.toLowerCase().replace(/ /g, "_") + "_star_" + generateStars(criteria_content.score)
        case CommentFieldsEnum.CR3:
            criteria_content = state.reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === ReportDetailsCriteriaEnum.CR3)
            return base + criteria_content.label.toLowerCase().replace(/ /g, "_") + "_star_" + generateStars(criteria_content.score)
        case CommentFieldsEnum.CR4:
            criteria_content = state.reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === ReportDetailsCriteriaEnum.CR4)
            return base + criteria_content.label.toLowerCase().replace(/ /g, "_") + "_star_" + generateStars(criteria_content.score)
        case CommentFieldsEnum.CR5:
            criteria_content = state.reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === ReportDetailsCriteriaEnum.CR5)
            return base + criteria_content.label.toLowerCase().replace(/ /g, "_") + "_star_" + generateStars(criteria_content.score)
    }
}


function generateStars(scoreString: string): string {
    let score = Number(scoreString)

    if ( score <= 69.9) {
        return "0"
    } else if (score >= 70 && score < 80) {
        return "1"
    } else if (score >= 80 && score < 90) {
        return "2"
    } else if (score >= 90) {
        return "3"
    }
    return ""
}

function generateAllComments(state: GptReportStateModel) : string[] {
    var comments = [...commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.GENERAL)]

    if (state.reportDetails.options.comments_suggestions) {
        comments.push( ...commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR2), 
        ...commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR3), 
        ...commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR4), 
        ...commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR5), 
        ...concactJuryContent(state.reportDetails.suggestions.jury_content))
    }

    return comments
}


function generateComments(state: GptReportStateModel, commentField: CommentFieldsEnum): string[] {
    switch (commentField) {
        case CommentFieldsEnum.OVERALL:
            return commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.GENERAL)
        case CommentFieldsEnum.CR2:
            return commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR2)
        case CommentFieldsEnum.CR3:
            return commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR3)
        case CommentFieldsEnum.CR4:
            return commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR4)
        case CommentFieldsEnum.CR5:
            return commentsForCriteriaType(state.reportDetails, ReportDetailsCriteriaEnum.CR5)
        case CommentFieldsEnum.SUGGESTION:
            return concactJuryContent(state.reportDetails.suggestions.jury_content)
        case CommentFieldsEnum.FOODPAIRING:
            return concatFoodPairingContent(state.reportDetails.pairing_option_content.jury_content)
    }

}


function commentFieldForKey(key: string): CommentFieldsEnum {

    if (key.includes("vision") || key.includes("faults")) {
        return CommentFieldsEnum.CR2
    }
    if (key.includes("olfaction") || key.includes("frutiness")) {
        return CommentFieldsEnum.CR3
    }
    if (key.includes("taste") || key.includes("bitterness")) {
        return CommentFieldsEnum.CR4
    }
    if (key.includes("texture") || key.includes("pungency")) {
        return CommentFieldsEnum.CR5
    }
    if (key.includes("suggestion")) {
        return  CommentFieldsEnum.SUGGESTION
    }
    if (key.includes("food_pairing")) {
        return CommentFieldsEnum.FOODPAIRING
    }

    return CommentFieldsEnum.OVERALL
}



function commentsForCriteriaType(reportDetails:IReportDetails, contentCriteriaEnum: ReportDetailsCriteriaEnum): string[] {
    var criteraContent = reportDetails.criteria_content.find(criteriaContent => criteriaContent.type === contentCriteriaEnum)
    return concactJuryContent(criteraContent.jury_content)
} 

function concactJuryContent(juryContent: IReportCriteriaJuryContent[]): string[] {
    const descriptions = juryContent.map(item => item.comment.translated_en)
    const positives = juryContent.map(item => item.comment.pos_en)
    const negatives = juryContent.map(item => item.comment.neg_en)

    return [...descriptions, ...positives, ...negatives]
}

function concatFoodPairingContent(juryContent: IFoodPairingJuryContent[]): string[] {
    var explanations = juryContent.map(item => item.pairing_explanation.translated_en + " : " + item.pairing_suggestion.translated_en)
    return [...explanations]
}



@State<GptReportStateModel>({
    name: 'gptReport',
})

@Injectable()
export class GptReportState {

    constructor(private gptService: GptService) {}

    @Selector()
    public static commentField(state: GptReportStateModel): CommentFieldsEnum {
        return state.commentField;
    }

    @Selector()
    public static reportDetails(state: GptReportStateModel): IReportDetails {
        return state.reportDetails;
    }

    @Selector()
    public static prompt(state: GptReportStateModel): string {
        return state.prompt
    }

    @Selector()
    public static generalPrompt(state: GptReportStateModel): string {
        return state.generalPrompt
    }
    
    @Selector()
    public static gptResult(state: GptReportStateModel): string {
        return state.gptResult
    }

    @Selector()
    public static allGptResults(state: GptReportStateModel): IGptChatGptDToIn {
        return state.allGptResults
    }

    @Action(GPT.SetValues)
    public setValues(ctx: StateContext<GptReportStateModel>, action: GPT.SetValues): void {
        ctx.patchState({
            commentField: action.commentField,
            reportDetails: action.reportDetails,
            gptResult: ''
        });
    }

    @Action(GPT.SetPrompt)
    public setPrompt(ctx: StateContext<GptReportStateModel>, action: GPT.SetPrompt): void {
        ctx.patchState({
            prompt: action.prompt
        });
    }

    @Action(GPT.SetGeneralPrompt)
    public setGeneralPrompt(ctx: StateContext<GptReportStateModel>, action: GPT.SetGeneralPrompt): void {
        ctx.patchState({
            generalPrompt: action.generalPrompt
        });
    }

    @Action(GPT.FetchPrompts)
    public fetchPrompts(ctx: StateContext<GptReportStateModel>): Observable<IGptPromptDtoIn> {
        const state: GptReportStateModel = ctx.getState();
          
        const keys: IGptPromptKeysDtOut = {
            keys : [reportCommonKey, generateKey(state)]
        }

        return this.gptService.getPromptForKeys(keys).pipe(
            tap((result: IGptPromptDtoIn) => {
                for (const prompt of result.prompts) {
                    if (prompt.key == reportCommonKey) {
                        ctx.patchState({generalPrompt: prompt.prompt})
                    } else {
                        ctx.patchState({prompt: prompt.prompt})
                    }
                  }
            })
        );
    }
    
    @Action(GPT.FetchAllPromptsAngGenerateGPT)
    public fetchAllPromptsAngGenerateGPT(ctx: StateContext<GptReportStateModel>): Observable<void> {
        const state: GptReportStateModel = ctx.getState();

        const keys: IGptPromptKeysDtOut = {
            keys : generateAllKeys(state)
        }

        return this.gptService.getPromptForKeys(keys).pipe(
            tap((result: IGptPromptDtoIn) => {
                const generalPrompt = result.prompts.find(prompt => prompt.key === reportCommonKey).prompt;

                if (generalPrompt) {   
                    result.prompts = result.prompts.filter(prompt => prompt.key !== reportCommonKey);
                }

                for (const prompt of result.prompts) {
                    var promptString = ""
                    
                    var comments = generateComments(state, commentFieldForKey(prompt.key))

                    if (prompt.key.includes("overrall")) { // Exception for overall comment
                        const allComments = generateAllComments(state)
                        promptString = (prompt.prompt.replace('{{general-prompt}}', generalPrompt.replace(/{{announcement}}/g, state.reportDetails.announcement_desc)).replace('{{general-comments}}', comments.join('\n• ')))
                        if (allComments.length > 0) {
                            promptString = promptString + "\nHere are the rest of others comments which are not as important:\n" +  allComments.join('\n• ') 
                        }
                    } else {
                        promptString = (prompt.prompt.replace('{{general-prompt}}', generalPrompt.replace(/{{announcement}}/g, state.reportDetails.announcement_desc))) + "\nHere are the product reviews that you need to summarise:\n" + comments.join('\n• ')
                    }
                    prompt.prompt = promptString
                }

                ctx.patchState({allPrompts: result});
            }),
            concatMap(() => ctx.dispatch(new GPT.FetchMultipleGptResults())) // Dispatcher la seconde action après la première
        );
    }

    @Action(GPT.UpdateGptPrompts) 
    public updategptPrompt(ctx: StateContext<GptReportStateModel>, action: GPT.UpdateGptPrompts): Observable<void> {
        const state: GptReportStateModel = ctx.getState();

        const values: IGptPromptsDtOut = {
            prompts : [
                {key: generateKey(state), prompt: state.prompt},
                {key: reportCommonKey, prompt: state.generalPrompt}
            ]
        }
        return this.gptService.updateGptPrompts(values);
    }

    @Action(GPT.FetchGptResult)
    public fetchGptResult(ctx: StateContext<GptReportStateModel>, action: GPT.FetchGptResult): Observable<IGptDtoIn> {
        const state: GptReportStateModel = ctx.getState();

        const comments = generateComments(state, state.commentField)
        
        var prompt = ""
        if (state.commentField == CommentFieldsEnum.OVERALL) { // Exception for overall comment
            const allComments = generateAllComments(state)
            prompt = (state.prompt.replace('{{general-prompt}}', state.generalPrompt.replace(/{{announcement}}/g, state.reportDetails.announcement_desc)).replace('{{general-comments}}', comments.join('\n• ')))
            if (allComments.length > 0) {
                prompt = prompt + "\nHere are the rest of others comments which are not as important:\n" +  allComments.join('\n• ') 
            }
        } else {
            prompt = (state.prompt.replace('{{general-prompt}}', state.generalPrompt.replace(/{{announcement}}/g, state.reportDetails.announcement_desc))) + "\nHere are the product reviews that you need to summarise:\n" + comments.join('\n• ')
        }

        return this.gptService.getGptResultByPrompt(prompt).pipe(
            tap((result: IGptDtoIn) => {
               ctx.patchState({gptResult: result.content});
            })
        );
    }

    @Action(GPT.FetchMultipleGptResults)
    public fetchMultipleGptResults(ctx: StateContext<GptReportStateModel>, action: GPT.FetchMultipleGptResults): Observable<IGptChatGptDToIn> {
        const state: GptReportStateModel = ctx.getState();
        return this.gptService.getGptResultsByPrompts(state.allPrompts).pipe(
            tap((result: IGptChatGptDToIn) => {
               ctx.patchState({allGptResults: result});
               ctx.patchState({allGptResults: { prompts: [] }});
            })
        );
    }

}