import { PromptEnum } from "src/shared/enums/prompt-enum.enum";


export namespace ReportPrompt {

    export class SetSelectedPrompt {
        public static readonly type = '[ReportPrompt] Set SelectedPrompt';
        constructor(public selectedPrompt?: PromptEnum) {}
    }
    
    export class FetchPrompts {
        public static readonly type = '[ReportPrompt] Fetch prompts';
    }

    export class SetPrompts {
        public static readonly type = '[ReportPrompt] Set prompts';
        constructor(public prompts?: string[]) {}
    }
    
    export class UpdatePrompts {
        public static readonly type = '[ReportPrompt] Update prompts';
    }


}
